import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  acquisitionCodes: [],
  freezePanel: { columns: 0, dataTable: "" },

  plannedOrders: { count: 0, results: [] },
  plannedOrdersParams: { limit: 10, offset: 0 },

  plannedOrderStatus: [],

  projectedStocks: { count: 0, results: [] },
  projectedStockParams: { limit: 10, offset: 0 },

  plannedOrdersProducts: [],
};

const plannerSlice = createSlice({
  name: "planner",
  initialState,
  reducers: {
    getAcquisitionCodes: (state, action) => {
      state.acquisitionCodes = action.payload;
    },
    getPlannedOrders: (state, action) => {
      state.plannedOrders = action.payload;
    },
    getPlannedOrderStatus: (state, action) => {
      state.plannedOrderStatus = action.payload;
    },
    getProjectedStocks: (state, action) => {
      state.projectedStocks = action.payload;
    },
    getPlannedOrdersProducts: (state, action) => {
      state.plannedOrdersProducts = action.payload;
    },
    removePlannedOrder: (state, action) => {
      const { ids } = action.payload;
      state.plannedOrders = {
        count: state.plannedOrders.count - ids.length,
        results: state.plannedOrders.results.filter(item => !ids.includes(item.id)),
      };
    },
    resetFreezePanel: (state, action) => {
      state.freezePanel = initialState.freezePanel;
    },
    resetPlannedOrders: state => {
      state.plannedOrders = initialState.plannedOrders;
    },
    resetPlannedOrdersParams: state => {
      state.plannedOrdersParams = { limit: state.plannedOrdersParams.limit, offset: 0 };
    },
    resetProjectedStocksParams: state => {
      state.projectedStockParams = { limit: state.projectedStockParams.limit, offset: 0 };
    },
    setFreezePanel: (state, action) => {
      state.freezePanel = action.payload;
    },
    setPlannedOrdersParams: (state, action) => {
      state.plannedOrdersParams = action.payload;
    },
    setProjectedStocksParams: (state, action) => {
      state.projectedStockParams = action.payload;
    },
    updatePlannedOrder: (state, action) => {
      const { id, ...rest } = action.payload;
      const index = state.plannedOrders.results.findIndex(plannedOrder => plannedOrder.id === id);
      if (index !== -1) {
        state.plannedOrders.results[index] = { ...state.plannedOrders.results[index], ...rest };
      }
    },
    updatePlannedOrdersParams: (state, action) => {
      state.plannedOrdersParams = { ...state.plannedOrdersParams, ...action.payload };
    },
    updatePlannedOrderStatus: (state, action) => {
      const { ids, ...rest } = action.payload;
      ids.forEach(id => {
        const index = state.plannedOrders.results.findIndex(plannedOrder => plannedOrder.id === id);
        if (index !== -1) {
          state.plannedOrders.results[index] = { ...state.plannedOrders.results[index], ...rest };
        }
      });
    },
    updateProjectedStocks: (state, action) => {
      state.projectedStocks = { ...state.projectedStocks, ...action.payload };
    },
    updateProjectedStocksParams: (state, action) => {
      state.projectedStockParams = { ...state.projectedStockParams, ...action.payload };
    },
  },
});

export const {
  getAcquisitionCodes,
  getPlannedOrders,
  getPlannedOrderStatus,
  getProjectedStocks,
  getPlannedOrdersProducts,
  removePlannedOrder,
  resetFreezePanel,
  resetPlannedOrders,
  resetPlannedOrdersParams,
  resetProjectedStocksParams,
  setFreezePanel,
  setPlannedOrdersParams,
  setProjectedStocksParams,
  updatePlannedOrder,
  updatePlannedOrdersParams,
  updatePlannedOrderStatus,
  updateProjectedStocks,
  updateProjectedStocksParams,
} = plannerSlice.actions;

export default plannerSlice.reducer;
