import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchaseAllocations: { count: 0, results: [] },
  purchaseAllocationsParams: { limit: 10, offset: 0 },

  transferAllocations: { count: 0, results: [] },
  transferAllocationsParams: { limit: 10, offset: 0 },

  transferOrderLine: null,
  transferOrderLines: { count: 0, results: [] },
  transferOrderLinesParams: { limit: 10, offset: 0 },
  transferOrderSelection: [],
  toLinesConsolidatePOIds: [],

  transferOrderLineStatus: [],

  purchaseOrderLineProducts: [],
  transferOrderLineProducts: [],

  poLinePurchaseOrders: [],
  toLineTransferOrders: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getTransferOrderLineStatus: (state, action) => {
      state.transferOrderLineStatus = action.payload;
    },
    getPurchaseOrderLineProducts: (state, action) => {
      state.purchaseOrderLineProducts = action.payload;
    },
    getTransferOrderLineProducts: (state, action) => {
      state.transferOrderLineProducts = action.payload;
    },
    getPoLinePurchaseOrders: (state, action) => {
      state.poLinePurchaseOrders = action.payload;
    },
    getToLineTransferOrders: (state, action) => {
      state.toLineTransferOrders = action.payload;
    },
    removePurchaseAllocations: (state, action) => {
      const { ids } = action.payload;
      state.purchaseAllocations = {
        count: state.purchaseAllocations.count - action.payload.ids.length,
        results: state.purchaseAllocations.results.filter(item => !ids.includes(item.id)),
      };
    },
    removeTransferAllocations: (state, action) => {
      const { ids } = action.payload;
      state.transferAllocations = {
        count: state.transferAllocations.count - action.payload.ids.length,
        results: state.transferAllocations.results.filter(item => !ids.includes(item.id)),
      };
    },
    resetPurchaseAllocations: state => {
      state.purchaseAllocations = initialState.purchaseAllocations;
    },
    resetPurchaseAllocationsParams: state => {
      state.purchaseAllocationsParams = initialState.purchaseAllocationsParams;
    },
    resetTransferAllocations: state => {
      state.transferAllocations = initialState.transferAllocations;
    },
    resetTransferAllocationsParams: state => {
      state.transferAllocationsParams = initialState.transferAllocationsParams;
    },
    resetTransferOrderLine: state => {
      state.transferOrderLine = initialState.transferOrderLine;
    },
    resetTransferOrderLines: state => {
      state.transferOrderLines = initialState.transferOrderLines;
    },
    resetTransferOrderLinesParams: state => {
      state.transferOrderLinesParams = initialState.transferOrderLinesParams;
    },
    setPurchaseAllocations: (state, action) => {
      const { count, results } = action.payload;
      state.purchaseAllocations = { count, results };
    },
    setPurchaseAllocationsParams: (state, action) => {
      state.purchaseAllocationsParams = action.payload;
    },
    setTransferAllocations: (state, action) => {
      const { count, results } = action.payload;
      state.transferAllocations = { count, results };
    },
    setTransferAllocationsParams: (state, action) => {
      state.transferAllocationsParams = action.payload;
    },
    setTransferOrders: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.transferOrderSelection = action.payload;
      }
    },
    setTransferOrderLine: (state, action) => {
      state.transferOrderLine = action.payload;
    },
    setTransferOrderLines: (state, action) => {
      const { count, results } = action.payload;
      state.transferOrderLines = { count, results };
    },
    setTransferOrderLinesParams: (state, action) => {
      state.transferOrderLinesParams = action.payload;
    },
    setTOLinesConsolidatePOIds: (state, action) => {
      state.toLinesConsolidatePOIds = action.payload;
    },
    resetTOLinesConsolidatePOIds: (state, action) => {
      state.toLinesConsolidatePOIds = initialState.toLinesConsolidatePOIds;
    },
    updatePurchaseAllocatedQty: (state, action) => {
      const { id, ...rest } = action.payload;
      const index = state.purchaseAllocations.results.findIndex(item => item.id === id);
      if (index !== -1) {
        state.purchaseAllocations.results[index] = {
          ...state.purchaseAllocations.results[index],
          ...rest,
        };
      }
    },
    updateTransferAllocatedQty: (state, action) => {
      const { allocatable, allocated_qty, id, product_plan, status } = action.payload;

      const allocationIndex = state.transferAllocations.results.findIndex(item => item.id === id);

      if (allocationIndex !== -1) {
        const allocation = state.transferAllocations.results[allocationIndex];
        state.transferAllocations.results[allocationIndex] = {
          ...allocation,
          allocatable,
          allocated_qty,
          status,
        };

        state.transferAllocations.results.forEach((item, index) => {
          if (item.id !== id && item.product_plan === product_plan) {
            state.transferAllocations.results[index] = {
              ...item,
              allocatable,
            };
          }
        });
      }
    },

    updatePurchaseAllocationsParams: (state, action) => {
      state.purchaseAllocationsParams = { ...state.purchaseAllocationsParams, ...action.payload };
    },
    updateTransferAllocationsParams: (state, action) => {
      state.transferAllocationsParams = { ...state.transferAllocationsParams, ...action.payload };
    },
    updateTransferOrderLinesParams: (state, action) => {
      state.transferOrderLinesParams = { ...state.transferOrderLinesParams, ...action.payload };
    },
    updateTransferOrderLineStatus: (state, action) => {
      const { ids, ...rest } = action.payload;
      ids.forEach(id => {
        const index = state.transferOrderLines.results.findIndex(
          transferOrder => transferOrder.id === id,
        );
        if (index !== -1) {
          state.transferOrderLines.results[index] = {
            ...state.transferOrderLines.results[index],
            ...rest,
          };
        }
      });
    },
    updateTransferOrderAllocationStatus: (state, action) => {
      const { ids, status } = action.payload;
      ids.forEach(id => {
        const index = state.transferAllocations.results.findIndex(
          transferOrder => transferOrder.id === id,
        );
        if (index !== -1) {
          state.transferAllocations.results[index] = {
            ...state.transferAllocations.results[index],
            status,
          };
        }
      });
    },
    updatePurchaseOrderAllocationStatus: (state, action) => {
      const { ids, status } = action.payload;
      ids.forEach(id => {
        const index = state.purchaseAllocations.results.findIndex(
          purchaseOrder => purchaseOrder.id === id,
        );
        if (index !== -1) {
          state.purchaseAllocations.results[index] = {
            ...state.purchaseAllocations.results[index],
            status,
          };
        }
      });
    },
  },
});

export const {
  getTransferOrderLineStatus,
  getPurchaseOrderLineProducts,
  getTransferOrderLineProducts,
  getPoLinePurchaseOrders,
  getToLineTransferOrders,
  removePurchaseAllocations,
  removeTransferAllocations,
  resetPurchaseAllocations,
  resetPurchaseAllocationsParams,
  resetTransferAllocations,
  resetTransferAllocationsParams,
  resetTransferOrderLine,
  resetTransferOrderLines,
  resetTransferOrderLinesParams,
  resetTOLinesConsolidatePOIds,
  setPurchaseAllocations,
  setPurchaseAllocationsParams,
  setTOLinesConsolidatePOIds,
  setTransferAllocations,
  setTransferAllocationsParams,
  setTransferOrders,
  setTransferOrderLine,
  setTransferOrderLines,
  setTransferOrderLinesParams,
  updatePurchaseAllocatedQty,
  updateTransferAllocatedQty,
  updatePurchaseAllocationsParams,
  updateTransferAllocationsParams,
  updateTransferOrderLinesParams,
  updateTransferOrderLineStatus,
  updateTransferOrderAllocationStatus,
  updatePurchaseOrderAllocationStatus,
} = orderSlice.actions;

export default orderSlice.reducer;
