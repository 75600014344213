import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { checkIsActive, KTIcon } from "../../../helpers";

const AsideMenuItem = ({ children, to, title, icon, fontIcon, hasBullet = false }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <div className="menu-item">
      <Link className={clsx("menu-link without-sub", { active: isActive })} to={to}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && (
          <span className="menu-icon">
            <KTIcon iconName={icon} className="fs-2" />
          </span>
        )}
        {fontIcon && <i className={clsx("bi fs-3", fontIcon)}></i>}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };
