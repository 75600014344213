import { apiSlice } from "./apiSlice";
import {
  createFiles,
  deleteFile,
  getDocTypes,
  getFile,
  getFiles,
  updateComplianceFile,
  updateFile,
  updateFileApproval,
  updateFileStatus,
  updateFileV2,
  updateMultiFileStatus,
} from "../slice/fileSlice";
import { setReminders, updateReminderList } from "../slice/complianceSlice";

export const filesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    approveDocument: builder.mutation({
      query: ({ id }) => ({
        url: `/api_docdigit/document/${id}/approve_document/`,
        method: "PATCH",
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateFileStatus(result.data.data)))
          .catch(error => {});
      },
    }),
    approveMultiDocuments: builder.mutation({
      query: data => ({
        url: `/api_docdigit/document/approve_multi_documents/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultiFileStatus(result.data)))
          .catch(error => {});
      },
    }),
    createDocReplacement: builder.mutation({
      query: data => ({
        url: "/api_docdigit/document/upload_partner_compliance_replacement/",
        method: "post",
        body: data,
      }),
    }),
    createFiles: builder.mutation({
      query: data => ({ url: "/api_docdigit/document/create_file/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(createFiles(result.data))).catch(error => {});
      },
    }),
    createFilesAutomatic: builder.mutation({
      query: data => ({
        url: "/api_docdigit/document/create_file_automatic/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(createFiles(result.data))).catch(error => {});
      },
    }),
    createReminderOption: builder.mutation({
      query: data => ({ url: "/api_docdigit/reminder_types/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateReminderList(result.data))).catch(error => {});
      },
    }),
    deleteFile: builder.mutation({
      query: ({ id }) => ({ url: `/api_docdigit/document/${id}/`, method: "DELETE" }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(deleteFile(result.data))).catch(error => {});
      },
    }),
    deleteFiles: builder.mutation({
      query: data => ({ url: `/api_docdigit/document/delete_files/`, method: "PATCH", body: data }),
    }),
    getDocTypes: builder.query({
      query: params =>
        params ? `/api_docdigit/documnt_types/?${params}` : "/api_docdigit/documnt_types/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getDocTypes(result.data))).catch(error => {});
      },
    }),
    getFile: builder.query({
      query: ({ id, params = null }) =>
        params ? `/api_docdigit/document/${id}/?${params}` : `/api_docdigit/document/${id}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getFile(result.data))).catch(error => {});
      },
    }),
    getFiles: builder.query({
      query: params => (params ? `/api_docdigit/document/?${params}` : "/api_docdigit/document/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getFiles(result.data))).catch(error => {});
      },
    }),
    getReminders: builder.query({
      query: () => `/api_docdigit/reminder_types/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setReminders(result.data))).catch(error => {});
      },
    }),
    sendReminder: builder.mutation({
      query: data => ({ url: "/api_docdigit/document_invite/", method: "post", body: data }),
    }),
    shareDirectMail: builder.mutation({
      query: data => ({ url: "api_docdigit/direct_file/", method: "POST", body: data }),
    }),
    updateApproval: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_docdigit/document/${id}/update_approval/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateFileApproval(result.data))).catch(error => {});
      },
    }),
    updateComplianceFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_docdigit/document/${id}/update_compliance_file/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateComplianceFile(result.data)))
          .catch(error => {});
      },
    }),
    updateFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_docdigit/document/${id}/update_file/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateFile(result.data))).catch(error => {});
      },
    }),
    updateFileRecipients: builder.mutation({
      query: data => ({ url: `/api_docdigit/modify_recipients/`, method: "PATCH", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateFileV2(result.data))).catch(error => {});
      },
    }),
    updateFileReminder: builder.mutation({
      query: data => ({ url: `/api_docdigit/modify_reminder/`, method: "PATCH", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updateFileV2(result.data))).catch(error => {});
      },
    }),
    updateReminderOption: builder.mutation({
      query: data => ({
        url: "/api_docdigit/reminder_types/update_alert/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setReminders(result.data.data))).catch(error => {});
      },
    }),
    validateDocToken: builder.query({ query: token => `/api_docdigit/validate_token/${token}/` }),
  }),
});

export const {
  useApproveDocumentMutation,
  useApproveMultiDocumentsMutation,
  useCreateDocReplacementMutation,
  useCreateFilesAutomaticMutation,
  useCreateFilesMutation,
  useCreateReminderOptionMutation,
  useDeleteFileMutation,
  useDeleteFilesMutation,
  useGetDocTypesQuery,
  useGetFilesQuery,
  useGetRemindersQuery,
  useLazyGetFileQuery,
  useLazyValidateDocTokenQuery,
  useSendReminderMutation,
  useShareDirectMailMutation,
  useUpdateApprovalMutation,
  useUpdateComplianceFileMutation,
  useUpdateFileMutation,
  useUpdateFileRecipientsMutation,
  useUpdateFileReminderMutation,
  useUpdateReminderOptionMutation,
} = filesApiSlice;
