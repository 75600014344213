import { useEffect, useState } from "react";
import * as XLSX from "xlsx";

import { useModalView } from "../Provider/Open";
import { KTIcon } from "../../../_metronic/helpers";
import { useNotification } from "../../../hooks/useNotification";
import { useCreateMultiplePurchaseOrdersMutation } from "../../../store/apiSlice/purchaseOrderApiSlice";

const UploadPurchaseOrder = () => {
  const { clearCurrentAction, currentAction } = useModalView();

  useEffect(() => {
    const body = document.body;
    if (currentAction === "uploadBulkPurchaseOrder") {
      body.classList.add("modal-open");
    }
    return () => body.classList.remove("modal-open");
  }, [currentAction]);

  if (!(currentAction === "uploadBulkPurchaseOrder")) return;

  return (
    <>
      <div
        id="ordlnk_modal_upload_files"
        className="d-block fade modal show"
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Upload File</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary cursor-pointer"
                onClick={() => clearCurrentAction()}
              >
                <KTIcon className="fs-1" iconName="cross" />
              </div>
            </div>
            <div className="modal-body mx-6 mx-xl-15 px-0">
              <UploadFileForm closeUploadModal={clearCurrentAction} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const UploadFileForm = () => {
  const { clearCurrentAction } = useModalView();
  const notify = useNotification();
  const [uploadedData, setUploadedData] = useState([]);

  const [createMultiplePurchaseOrders] = useCreateMultiplePurchaseOrdersMutation();

  const handleUploadedFile = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async e => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary", cellText: false, cellDates: true });
      const firstSheet = workbook.SheetNames[0];
      const firstWorksheet = workbook.Sheets[firstSheet];
      const secondSheet = workbook.SheetNames[1];
      const secondWorksheet = workbook.Sheets[secondSheet];

      const firstSheetJSON = XLSX.utils.sheet_to_json(firstWorksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const secondSheetJSON = XLSX.utils.sheet_to_json(secondWorksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });

      const mapped_po_line = firstSheetJSON.map(item => {
        return { ...item, purchase_order_line_items: [] };
      });

      function roundTo(num, precision) {
        const factor = Math.pow(10, precision);
        return Math.round(num * factor) / factor;
      }

      mapped_po_line.forEach(item =>
        // eslint-disable-next-line array-callback-return
        secondSheetJSON.map(data => {
          if (item?.id === data?.purchase_order) {
            data["ordered_qty"] = roundTo(data?.ordered_qty, 8);
            data["unit_price"] = roundTo(data?.unit_price, 8);
            item?.purchase_order_line_items.push({ ...data });
          }
        }),
      );
      setUploadedData(mapped_po_line);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      notify({ type: "success", message: "File has been uploaded succesfully." }); // Show success notification
      const createdResponse = await createMultiplePurchaseOrders(uploadedData).unwrap();
      notify({ type: "success", message: createdResponse.detail }); // Show success notification
    } catch (error) {
      notify({ type: "error", message: error.data?.detail || "Something Went Wrong" });
    }
    clearCurrentAction();
  };

  return (
    <form className="form" encType="multipart/form-data" onSubmit={handleSubmit}>
      {/* Multiple Upload File Input */}
      <div className="row mb-8">
        <label className="fs-6 fw-medium text-gray mb-2 required" htmlFor="files-input">
          Upload File
        </label>
        <div className="col fv-row">
          <input
            id="files-input"
            className="form-control form-control-solid mb-3 mb-lg-0"
            multiple={true}
            name="files"
            type="file"
            onChange={handleUploadedFile}
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="text-center">
        <button className="btn btn-primary" disabled={!uploadedData?.length} type="submit">
          <span className="indicator-label">Submit</span>
        </button>
      </div>
    </form>
  );
};

export { UploadPurchaseOrder };
