import { AsideMenuItem } from "./AsideMenuItem";
import {
  CreateReceipt,
  POLineItemTH,
  ReceiptLineItemC,
  ReceiptLineItemV,
  SupplierDIFOTMetrics,
  SupplierLeadTimeMetrics,
} from "../../../../constant/permissions";
import { hasPermission } from "../../../../helper/permissions";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuModal } from "./AsideMenuModal";

export function AsideMenuMain() {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth) || {};

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
      />

      <AsideMenuItemWithSub title="Planner" to="/planner" icon="package">
        <AsideMenuItem hasBullet={true} title="Planned Orders" to="/planner/planned_order" />
        <AsideMenuItem hasBullet={true} title="Projected Stocks" to="/planner/projected_stock" />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub title="Orders" to="/orders" icon="handcart">
        <AsideMenuItem hasBullet={true} title="Purchase Orders" to="/purchase_orders" />
        <AsideMenuItem hasBullet={true} title="Purchase Order Lines" to="/orders/purchase" />
        <AsideMenuItem hasBullet={true} title="Transfer Orders" to="/orders/transfer" />
        <AsideMenuItem hasBullet={true} title="Consolidated Orders" to="/orders/consolidate" />
        <AsideMenuItem hasBullet={true} title="Allocations" to="/orders/allocation" />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub title="Inventory" to="/inventory" icon="graph-4">
        <AsideMenuItem hasBullet={true} title="Summary" to="/inventory/summary" />
        <AsideMenuItem hasBullet={true} title="Details" to="/inventory/details" />
        <AsideMenuItem hasBullet={true} title="Transactions" to="/inventory/transactions" />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub title="Shipment" icon="delivery">
        <AsideMenuItem hasBullet={true} title="Local Shipments" to="/shipments" />
        <AsideMenuItem
          hasBullet={true}
          title="International Shipment"
          to="/shipment/international"
        />
        <AsideMenuItem hasBullet={true} title="International Shipments" to="/shipment/lines" />
        <AsideMenuItem hasBullet={true} title="ASN" to="/shipment/asn" />
      </AsideMenuItemWithSub>

      {hasPermission(
        [CreateReceipt, ReceiptLineItemC, ReceiptLineItemV, POLineItemTH],
        user.permissions,
      ) && <AsideMenuItem icon="notepad" title="Receipts" to="/receipts" />}

      <AsideMenuItem icon="document" title="Invoices" to="/invoices" />

      <AsideMenuItemWithSub title="Compliance" to="/compliance" icon="verify">
        <AsideMenuItem hasBullet={true} title="PO Compliance" to="/compliance/po" />
        <AsideMenuItem hasBullet={true} title="Partner Compliance" to="/compliance/partner" />
      </AsideMenuItemWithSub>

      <AsideMenuItem icon="switch" title="Documents" to="/file-manager" />

      <AsideMenuItem to="/chat" title="Inbox" icon="message-text-2" />

      {(hasPermission([SupplierDIFOTMetrics, SupplierLeadTimeMetrics], user.permissions) ||
        user.is_superuser) && (
        <AsideMenuItem icon="abstract-14" title="Metrics" to="/supplier-metrics" />
      )}

      {user.integration_allowed && (
        <AsideMenuItem icon="technology" title="Integrations" to="/integration" />
      )}

      <AsideMenuItem icon="abstract-26" title="Master" to="/master" />

      <AsideMenuItem icon="people" title="Users" to="/users" />

      <AsideMenuModal
        icon="setting-2"
        title={user?.groups[0]?.name || "Superuser"}
        currentUser={user}
        to="#"
      />
    </>
  );
}
