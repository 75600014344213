import { apiSlice } from "./apiSlice";
import {
  addActivityLogToPurchaseOrder,
  addPurchaseOrderWatchlist,
  createMultiplePurchaseOrders,
  doVerifyPO,
  getDownloadedPurchaseOrders,
  getDownloadedConsolidate,
  getDownloadedPurchaseOrderLines,
  getPOWatchlistLate,
  getPurchaseOrder,
  getPurchaseOrderFields,
  getPurchaseOrderLineFields,
  getPurchaseOrderLineStatus,
  getPurchaseOrderPriority,
  getPurchaseOrders,
  getPurchaseOrderStatus,
  getPurchaseOrdersWatchlists,
  getPurchaseOrdersWatchlistsDetail,
  getPurchaseOrderTrack,
  getPurchaseOrderPOLines,
  mutatePOLine,
  poProcess,
  processETAApprovalRequest,
  setConsolidatePOLines,
  setPOLineETA,
  setPOLines,
  updatePOLine,
  updatePOLReceiptedQty,
  updateMultiPOLReceiptedQty,
  updateMultiplePurchaseOrders,
  updatePurchaseOrderWatchlist,
} from "../slice/purchaseOrderSlice";

export const purchaseOrderApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createMultiplePurchaseOrders: builder.mutation({
      query: data => ({
        url: "/api_po/purchase_order/create_multiple_purchase_orders/",
        method: "post",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(createMultiplePurchaseOrders(result.data.data)))
          .catch(error => {});
      },
    }),
    createPurchaseOrder: builder.mutation({
      query: data => ({ url: "/api_po/purchase_order/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => {}).catch(error => {});
      },
    }),
    createPurchaseOrdersWatchlist: builder.mutation({
      query: data => ({ url: "/api_po/watchlist/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result =>
            dispatch(addPurchaseOrderWatchlist({ data: result.data.data, message: "create" })),
          )
          .catch(error => {});
      },
    }),
    downloadPurchaseOrderPdf: builder.query({
      query: id => ({
        url: `/api_po/purchase_order/${id}/download_pdf/`,
        method: "GET",
        headers: { "Content-Type": "application/pdf" },
        responseHandler: response => response.blob(),
      }),
    }),
    editPOLReceiptedQty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `api_po/po_line/${id}/update_receipted_qty/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updatePOLReceiptedQty(result.data)))
          .catch(error => {});
      },
    }),
    editMultiPOLReceiptedQty: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `api_po/po_line/update_recipted_qts/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultiPOLReceiptedQty(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedPurchaseOrders: builder.query({
      query: params =>
        params
          ? `/api_po/purchase_order/download_records/?${params}`
          : "/api_po/purchase_order/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedPurchaseOrders(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedConsolidate: builder.query({
      query: params =>
        params
          ? `/api_po/po_line/download_records/?${params}`
          : "/api_po/po_line/download_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedConsolidate(result.data.data)))
          .catch(error => {});
      },
    }),
    getDownloadedPurchaseOrderLines: builder.query({
      query: params =>
        params
          ? `/api_po/po_line/download_po_lines_records/?${params}`
          : "/api_po/po_line/download_po_lines_records/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getDownloadedPurchaseOrderLines(result.data.data)))
          .catch(error => {});
      },
    }),
    getPOActivityLogs: builder.query({
      query: ({ poID }) => `/api_po/po_activity_log/${poID}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(addActivityLogToPurchaseOrder(result.data)))
          .catch(error => {});
      },
    }),
    getPOLines: builder.query({
      query: (params = null) => (params ? `/api_po/po_line/?${params}` : "/api_po/po_line/"),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setPOLines(result.data))).catch(error => {});
      },
    }),
    getConsolidatePOLines: builder.query({
      query: params => `/api_po/po_line/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(setConsolidatePOLines(result.data)))
          .catch(error => {});
      },
    }),
    getPOLineSubDetail: builder.query({
      query: params => `/api_arepa/stock_move/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(updatePOLine(result.data))).catch(error => {});
      },
    }),
    getPOWatchlistLate: builder.query({
      query: () => "/api_po/late_purchase_order/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getPOWatchlistLate(result.data))).catch(error => {});
      },
    }),
    getPurchaseOrderDetail: builder.query({
      query: (id, params = null) =>
        params ? `/api_po/purchase_order/${id}/?${params}` : `/api_po/purchase_order/${id}/`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getPurchaseOrder(result.data))).catch(error => {});
      },
    }),
    getPurchaseOrderFields: builder.query({
      query: () => "/api_po/purchase_order_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderFields(result.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrderLineFields: builder.query({
      query: () => "/api_po/purchase_order_line_fields/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderLineFields(result.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrderLineStatus: builder.query({
      query: () => "/api_po/purchase_order_line_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderLineStatus(result.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrderPriority: builder.query({
      query: () => "/api_po/purchase_order_priority/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderPriority(result.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrders: builder.query({
      query: (params = null) =>
        params ? `/api_po/purchase_order/?${params}` : "/api_po/purchase_order/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(getPurchaseOrders(result.data))).catch(error => {});
      },
    }),
    getPurchaseOrderStatus: builder.query({
      query: (params = null) =>
        params ? `/api_po/purchase_order_status/?${params}` : "/api_po/purchase_order_status/",
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderStatus(result.data.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrdersTracklist: builder.query({
      query: params => `/api_po/purchase_order_track/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderTrack(result.data.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrdersWatchlist: builder.query({
      query: params => `/api_po/watchlist/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrdersWatchlists(result.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrdersWatchlistDetail: builder.query({
      query: params => `/api_po/watchlist/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrdersWatchlistsDetail(result.data.data)))
          .catch(error => {});
      },
    }),
    getPurchaseOrderPOLines: builder.query({
      query: params => `/api_po/get_po_lines/?${params}`,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrderPOLines(result.data.data)))
          .catch(error => {});
      },
    }),
    handleETAApprovalRequest: builder.mutation({
      query: ({ arId, data }) => ({
        url: `/api_affirm/approval_request/${arId}/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(processETAApprovalRequest(result.data)))
          .catch(error => {});
      },
    }),
    poProcess: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api_po/purchase_order/${id}/po_process/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(poProcess(result.data))).catch(error => {});
      },
    }),
    refreshPOReceipting: builder.query({ query: () => `/api_po/refresh_email_po_receipting/` }),
    shareatchlistDirectMail: builder.mutation({
      query: data => ({ url: "api_po/watchlist_share_email/", method: "POST", body: data }),
    }),
    updateDeletePurchaseOrdersWatchlist: builder.mutation({
      query: data => ({ url: "/api_po/update_watchlist/", method: "post", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result =>
            dispatch(addPurchaseOrderWatchlist({ data: result.data.data, message: "update" })),
          )
          .catch(error => {});
      },
    }),
    updatePOLineETA: builder.mutation({
      query: ({ poLineId, data }) => ({
        url: `/api_po/po_line_eta/${poLineId}/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(setPOLineETA(result.data))).catch(error => {});
      },
    }),
    updatePOLineReconcilationReceipt: builder.mutation({
      query: ({ id, ...restData }) => ({
        url: `/api_arepa/stock_move/`,
        method: "PATCH",
        body: restData,
      }),
      onQueryStarted: (data, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(mutatePOLine(data))).catch(error => {});
      },
    }),
    updatePurchaseOrdersWatchlist: builder.mutation({
      query: data => ({ url: `/api_po/watchlist/${data.id}/`, method: "PATCH", body: data }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updatePurchaseOrderWatchlist(result.data.data)))
          .catch(error => {});
      },
    }),
    verifyPO: builder.mutation({
      query: data => ({
        url: `/api_po/purchase_order/dispatch_verification_notification/`,
        method: "POST",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(result => dispatch(doVerifyPO(result.data))).catch(error => {});
      },
    }),
    updatePurchaseOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api_po/purchase_order/${id}/update_purchase_order/`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(getPurchaseOrder(result.data.data)))
          .catch(error => {});
      },
    }),
    updateMultiplePurchaseOrders: builder.mutation({
      query: data => ({
        url: "/api_po/purchase_order/update_downloaded_purchase_orders/",
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(result => dispatch(updateMultiplePurchaseOrders(result.data.data)))
          .catch(error => {});
      },
    }),
  }),
});

export const {
  useCreateMultiplePurchaseOrdersMutation,
  useCreatePurchaseOrderMutation,
  useCreatePurchaseOrdersWatchlistMutation,
  useEditPOLReceiptedQtyMutation,
  useEditMultiPOLReceiptedQtyMutation,
  useGetPOLinesQuery,
  useGetPOWatchlistLateQuery,
  useGetPurchaseOrderDetailQuery,
  useGetPurchaseOrderFieldsQuery,
  useGetPurchaseOrderLineFieldsQuery,
  useGetPurchaseOrderLineStatusQuery,
  useGetPurchaseOrderPriorityQuery,
  useGetPurchaseOrdersQuery,
  useGetPurchaseOrderStatusQuery,
  useGetPurchaseOrdersTracklistQuery,
  useGetPurchaseOrdersWatchlistDetailQuery,
  useLazyGetPurchaseOrderPOLinesQuery,
  useGetPurchaseOrdersWatchlistQuery,
  useHandleETAApprovalRequestMutation,
  useLazyDownloadPurchaseOrderPdfQuery,
  useLazyGetDownloadedPurchaseOrdersQuery,
  useLazyGetDownloadedConsolidateQuery,
  useLazyGetDownloadedPurchaseOrderLinesQuery,
  useLazyGetPOActivityLogsQuery,
  useLazyGetPOLinesQuery,
  useGetConsolidatePOLinesQuery,
  useLazyGetPOLineSubDetailQuery,
  useLazyGetPurchaseOrderDetailQuery,
  useLazyGetPurchaseOrdersQuery,
  usePoProcessMutation,
  useRefreshPOReceiptingQuery,
  useShareatchlistDirectMailMutation,
  useUpdateDeletePurchaseOrdersWatchlistMutation,
  useUpdatePOLineETAMutation,
  useUpdatePOLineReconcilationReceiptMutation,
  useUpdatePurchaseOrdersWatchlistMutation,
  useVerifyPOMutation,
  useUpdatePurchaseOrderMutation,
  useUpdateMultiplePurchaseOrdersMutation,
} = purchaseOrderApiSlice;
