import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import { useModalView } from "../Provider/Open";
import { KTCard, KTIcon } from "../../../_metronic/helpers";
import {
  useGetPurchaseOrderFieldsQuery,
  useGetPurchaseOrderLineFieldsQuery,
  useGetPurchaseOrderPriorityQuery,
} from "../../../store/apiSlice/purchaseOrderApiSlice";
import { useGetProductsQuery } from "../../../store/apiSlice/productApiSlice";
import {
  useGetSuppliersQuery,
  useGetWarehouseQuery,
} from "../../../store/apiSlice/supplierApiSlice";

const CreatePurchaseOrderAction = () => {
  const { clearCurrentAction, currentAction } = useModalView();

  useEffect(() => {
    const body = document.body;
    if (currentAction === "createPurchaseOrderAction") {
      body.classList.add("modal-open");
    }
    return () => body.classList.remove("modal-open");
  }, [currentAction]);

  if (!(currentAction === "createPurchaseOrderAction")) return;

  return (
    <>
      <div
        id="ordlnk_modal_update_product_cat"
        className="modal fade show d-block"
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered mw-400px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Create Purchase Orders</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary cursor-pointer"
                onClick={() => clearCurrentAction()}
              >
                <KTIcon className="fs-1" iconName="cross" />
              </div>
            </div>
            <div className="modal-body scroll-y">
              <Actions />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const Actions = () => {
  const { setCurrentAction, clearCurrentAction } = useModalView();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/orders/purchase/create");
    clearCurrentAction();
  };

  const { purchaseOrderFields, purchaseOrderLineFields, purchaseOrderPriority } = useSelector(
    state => state.purchaseOrder,
  );
  const { products } = useSelector(state => state.product);
  const { suppliers, warehouses } = useSelector(state => state.supplier);

  useGetPurchaseOrderFieldsQuery("", { refetchOnMountOrArgChange: true });
  useGetPurchaseOrderLineFieldsQuery("", { refetchOnMountOrArgChange: true });
  useGetSuppliersQuery("mode=selection", { refetchOnMountOrArgChange: true });
  useGetWarehouseQuery("type=selection", { refetchOnMountOrArgChange: true });
  useGetProductsQuery("type=selection", { refetchOnMountOrArgChange: true });
  useGetPurchaseOrderPriorityQuery("", { refetchOnMountOrArgChange: true });

  const handleExcel = () => {
    let updated_fields = [...purchaseOrderFields];
    let index = updated_fields.findIndex(item => item === "wh_opr_type");
    if (index !== -1) {
      updated_fields[index] = "warehouse";
    }
    const purchase_order_fields_sheet = XLSX.utils.aoa_to_sheet([updated_fields]);
    const purchase_order_line_fields_sheet = XLSX.utils.aoa_to_sheet([purchaseOrderLineFields]);
    const suppliers_sheet = XLSX.utils.json_to_sheet(suppliers);
    const warehouses_sheet = XLSX.utils.json_to_sheet(warehouses);
    const products_sheet = XLSX.utils.json_to_sheet(products);
    const priority_sheet = XLSX.utils.json_to_sheet(purchaseOrderPriority);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, purchase_order_fields_sheet, "purchase_order");
    XLSX.utils.book_append_sheet(wb, purchase_order_line_fields_sheet, "purchase_order_lines");
    XLSX.utils.book_append_sheet(wb, suppliers_sheet, "suppliers");
    XLSX.utils.book_append_sheet(wb, warehouses_sheet, "warehouses");
    XLSX.utils.book_append_sheet(wb, products_sheet, "products");
    XLSX.utils.book_append_sheet(wb, priority_sheet, "priority");

    XLSX.writeFile(wb, "purchsaeorders.xlsx");
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center gap-10 text-center">
        <KTCard className="w-100">
          <div className="container px-8 d-flex justify-content-between">
            <span
              onClick={() => handleNavigate()}
              className="d-flex flex-row align-items-center gap-5 text-start text-gray-800 cursor-pointer"
            >
              <div className="d-flex flex-column justify-content-start text-gray-800 text-hover-primary">
                <span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Individual</span>
                <span>Add one purchase order at a time</span>
              </div>
            </span>
          </div>
        </KTCard>

        <KTCard className="w-100">
          <div className="container px-8 d-flex justify-content-between">
            <div className="d-flex flex-column align-items-center gap-5 text-start text-gray-800">
              <div className="d-flex flex-column justify-content-start text-gray-800">
                <span style={{ fontSize: "1.25rem", fontWeight: "600" }}>Bulk Upload</span>
                <span>
                  Download Excel template, input purchase order data and upload Excel file
                </span>
              </div>
              <div className="d-flex flex-row gap-10">
                <span onClick={handleExcel}>
                  <KTIcon
                    iconName="file-down"
                    className="fs-2tx me-n1 minimize-default cursor-pointer text-hover-primary"
                  />
                </span>
                <span onClick={() => setCurrentAction("uploadBulkPurchaseOrder")}>
                  <KTIcon
                    iconName="file-up"
                    className="fs-2tx me-n1 minimize-default cursor-pointer text-hover-primary"
                  />
                </span>
              </div>
            </div>
          </div>
        </KTCard>
      </div>
    </>
  );
};

export { CreatePurchaseOrderAction };
