import { authSuccess, logOut } from "./authSlice";
import {
  updatePurchaseAllocationsParams,
  updateTransferAllocationsParams,
  updateTransferOrderLinesParams,
} from "./orderSlice";
import { updateASNListParams, updateConsignmentsParams } from "./shipmentSlice";
import { updateFilesParams } from "./fileSlice";
import { updatePlannedOrdersParams } from "./plannerSlice";
import { updatePOWatchlistLateParams, updatePurchaseOrdersParams } from "./purchaseOrderSlice";
import { setProductsParams, setProductPlansParams, setProductAliasParams } from "./productSlice";
import { setWarehousesParams, setSuppliersParams } from "./supplierSlice";
import {
  setForecastsParams,
  setStockOnHandParams,
  setWarehouseCostingParams,
  setWarehouseCostingRangeParams,
} from "./warehouseSlice";
import { updateSettings } from "./miscSlice";

const updateParams = (props, dispatch) => {
  if (props?.purchase_allocations_items_per_page) {
    dispatch(updatePurchaseAllocationsParams({ limit: props.purchase_allocations_items_per_page }));
  }

  if (props?.transfer_allocations_items_per_page) {
    dispatch(updateTransferAllocationsParams({ limit: props.transfer_allocations_items_per_page }));
  }

  if (props?.asnList_items_per_page) {
    dispatch(updateASNListParams({ limit: props.asnList_items_per_page }));
  }

  if (props?.files_items_per_page) {
    dispatch(updateFilesParams({ limit: props.files_items_per_page }));
  }

  if (props?.international_shipments_items_per_page) {
    dispatch(updateConsignmentsParams({ limit: props.international_shipments_items_per_page }));
  }

  if (props?.planned_orders_items_per_page) {
    dispatch(updatePlannedOrdersParams({ limit: props.planned_orders_items_per_page }));
  }

  if (props?.po_items_per_page) {
    dispatch(updatePurchaseOrdersParams({ limit: props.po_items_per_page }));
  }

  if (props?.po_watchlist_items_per_page) {
    dispatch(updatePOWatchlistLateParams({ limit: props.po_watchlist_items_per_page }));
  }

  if (props?.transfer_order_lines_items_per_page) {
    dispatch(updateTransferOrderLinesParams({ limit: props.transfer_order_lines_items_per_page }));
  }

  if (props?.master_products_items_per_page) {
    dispatch(setProductsParams({ limit: props.master_products_items_per_page }));
  }

  if (props?.master_product_plan_items_per_page) {
    dispatch(setProductPlansParams({ limit: props.master_product_plan_items_per_page }));
  }

  if (props?.master_product_alias_items_per_page) {
    dispatch(setProductAliasParams({ limit: props.master_product_alias_items_per_page }));
  }

  if (props?.master_warehouses_items_per_page) {
    dispatch(setWarehousesParams({ limit: props.master_warehouses_items_per_page }));
  }

  if (props?.master_suppliers_items_per_page) {
    dispatch(setSuppliersParams({ limit: props.master_suppliers_items_per_page }));
  }

  if (props?.master_forecast_items_per_page) {
    dispatch(setForecastsParams({ limit: props.master_forecast_items_per_page }));
  }

  if (props?.master_stock_on_hand_items_per_page) {
    dispatch(setStockOnHandParams({ limit: props.master_stock_on_hand_items_per_page }));
  }

  if (props?.master_warehouse_costing_items_per_page) {
    dispatch(setWarehouseCostingParams({ limit: props.master_warehouse_costing_items_per_page }));
  }

  if (props?.master_warehouse_costing_range_items_per_page) {
    dispatch(
      setWarehouseCostingRangeParams({
        limit: props.master_warehouse_costing_range_items_per_page,
      }),
    );
  }
};

const updatePrecision = (precision, dispatch) => {
  if (precision) {
    dispatch(updateSettings({ precision }));
  }
};

export const authenticationFailed = () => dispatch => {
  localStorage.setItem("authOut", Date.now());
  dispatch(logOut());
};

export const authenticationSuccess = data => dispatch => {
  localStorage.setItem("authIn", Date.now());
  dispatch(authSuccess(data));
  updateParams(data.limit, dispatch);
  updatePrecision(data.precision, dispatch);
};

export const logOutUser = () => dispatch => {
  localStorage.setItem("authOut", Date.now());
  dispatch(logOut());
};

export const authOnSuccess = data => dispatch => {
  dispatch(authSuccess(data));
  updateParams(data.limit, dispatch);
  updatePrecision(data.precision, dispatch);
};
